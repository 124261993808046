import React, { useEffect } from 'react'
import queryString from 'query-string'

import VideoPlayer from 'components/video-player'

export default function PlayerPage({ location }) {
  const { key } = location.search && queryString.parse(location.search)
  useEffect(() => {
    document.body.style.setProperty('display', 'flex', 'important')
    document.body.style.setProperty('justify-content', 'center', 'important')
  }, [])

  return <VideoPlayer mediaKey={key} />
}
